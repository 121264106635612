import { useEffect } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { BaseNavbar, Layout } from '@/shared/components';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { useScreenSize } from '@/shared/hooks';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import { SignIn } from '../components/Onboard/Steps/SignIn';
import { ViewOnlySignIn } from '../components/Onboard/Steps/ViewOnlySignIn';
import { useStateChainAccount } from '../hooks/useStateChainAccount';
import Hero from '../public/bg-circular-gradient.svg';

export default function Index(): JSX.Element {
  const router = useRouter();
  const { selectedAccount } = usePolkadot();
  const { accountInfo } = useStateChainAccount();

  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (accountInfo) {
      if (accountInfo.role === 'liquidity_provider') {
        router.push(
          typeof router.query.redirectUrl === 'string' ? router.query.redirectUrl : '/portfolio',
        );
      } else {
        router.push('/onboard');
      }
    }
  }, [accountInfo]);

  return (
    <Layout
      navbar={
        <BaseNavbar
          isMobileView={isMobile}
          logoHref="/"
          className={classNames(isMobile && 'h-min', 'border-b border-b-cf-gray-3')}
        />
      }
    >
      <div
        className={classNames(
          isMobile ? 'w-full justify-start p-2' : 'justify-center',
          'flex grow flex-col items-center',
        )}
      >
        <Hero className="pointer-events-none absolute top-[-80px] hidden h-full md:block" />
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedAccount !== null ? <LoadingSpinner /> : isMobile ? <ViewOnlySignIn /> : <SignIn />}
      </div>
    </Layout>
  );
}
