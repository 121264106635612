import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as ss58 from '@chainflip/utils/ss58';
import { abbreviate } from '@chainflip/utils/string';
import classNames from 'classnames';
import { Button } from '@/shared/components';
import { UserIcon } from '@/shared/icons/large';
import { defaultAccountId } from '../../ViewOnlyAccountModal';

const CHAR_WIDTH = 8;

export const ViewOnlySignIn = (): JSX.Element => {
  const [accountId, setAccountId] = useState(defaultAccountId || '');
  const [abbreviatedAccountId, setAbbreviatedAccountId] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [focusedValue, setFocusedValue] = useState('');
  const router = useRouter();

  const hasError = useMemo(() => {
    if (accountId === '') return false;

    try {
      ss58.decode(accountId);
      return false;
    } catch {
      return true;
    }
  }, [accountId]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const calculateAbbreviation = () => {
    const inputWidth = inputRef.current?.getBoundingClientRect().width || 0;
    const maxCharsPerInput = Math.floor(inputWidth / CHAR_WIDTH);

    if (accountId.length > maxCharsPerInput)
      setAbbreviatedAccountId(abbreviate(accountId, Math.floor(maxCharsPerInput / 2)));
    else setAbbreviatedAccountId(accountId);
  };

  useEffect(() => {
    calculateAbbreviation();
    window.addEventListener('resize', calculateAbbreviation);

    return () => {
      window.removeEventListener('resize', calculateAbbreviation);
    };
  }, [accountId]);

  return (
    <div className="flex w-full flex-col gap-y-6 text-14">
      <div className="flex flex-col gap-y-2">
        <h1 className="font-aeonikMedium text-[32px]">View-only Mode</h1>
        <span className="text-cf-light-2">Chainflip only supports portfolio view on mobile</span>
      </div>
      <div className="flex flex-col gap-y-5">
        <div className="flex items-start gap-x-1">
          <UserIcon color="#46DA93" />
          <span className="font-aeonikMedium text-cf-light-2">Chainflip Account ID</span>
        </div>
        <div className="relative flex flex-col">
          <input
            ref={inputRef}
            type="text"
            placeholder="cF..."
            value={isFocused ? focusedValue : abbreviatedAccountId}
            onChange={(e) => {
              setFocusedValue(e.target.value);
              setAccountId(e.target.value);
            }}
            className={classNames(
              `w-full border-b border-[#2B2B2B] bg-transparent pb-2.5 text-left text-14 text-cf-light-1 outline-none`,
              isFocused && 'text-white',
              hasError && 'border-cf-red-1',
            )}
            onFocus={() => {
              setFocusedValue('');
              setIsFocused(true);
            }}
            onBlur={() => {
              setFocusedValue(accountId);
              setIsFocused(false);
            }}
            spellCheck={false}
          />
          {hasError && (
            <span className="absolute -bottom-5 text-12 text-cf-red-1">
              Enter a valid account ID
            </span>
          )}
        </div>
      </div>
      <Button
        type="primary-standard"
        onClick={() => router.replace({ query: { ...router.query, accountId } })}
        disabled={hasError}
      >
        View account portfolio
      </Button>
    </div>
  );
};
